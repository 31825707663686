var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "number-filter" }, [
    _c("div", { staticClass: "has-text-centered" }, [
      _c(
        "a",
        {
          staticClass: "button is-small is-bold",
          class: { "is-success": _vm.filter.mode !== "interval" },
          on: {
            click: function($event) {
              _vm.filter.mode = null
              _vm.filter.value = null
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.i18n("Value")) + " ")]
      ),
      _c(
        "a",
        {
          staticClass: "button is-small is-bold has-margin-left-medium",
          class: { "is-success": _vm.filter.mode === "interval" },
          on: {
            click: function($event) {
              _vm.filter.mode = "interval"
              _vm.filter.value = _vm.interval()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.i18n("Interval")) + " ")]
      )
    ]),
    _vm.filter.mode === "interval"
      ? _c("div", { staticClass: "has-margin-top-medium" }, [
          _c("div", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("div", { staticClass: "level-item" }, [
                _c("input", {
                  directives: [
                    { name: "focus", rawName: "v-focus" },
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.filter.value.min,
                      expression: "filter.value.min",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "input",
                  attrs: { placeholder: _vm.i18n("Min") },
                  domProps: { value: _vm.filter.value.min },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.filter.value,
                        "min",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "level-right" }, [
              _c("div", { staticClass: "level-item" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.filter.value.max,
                      expression: "filter.value.max",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "input",
                  attrs: { placeholder: _vm.i18n("Max") },
                  domProps: { value: _vm.filter.value.max },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.filter.value,
                        "max",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ])
            ])
          ])
        ])
      : _c("input", {
          directives: [
            { name: "focus", rawName: "v-focus" },
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.filter.value,
              expression: "filter.value",
              modifiers: { number: true }
            }
          ],
          staticClass: "input has-margin-top-medium",
          attrs: { placeholder: _vm.i18n("Filter") },
          domProps: { value: _vm.filter.value },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.filter, "value", _vm._n($event.target.value))
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }