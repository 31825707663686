var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    { staticClass: "control is-expanded has-icons-right" },
    [
      _c("input", {
        directives: [
          { name: "focus", rawName: "v-focus" },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.filter.value,
            expression: "filter.value"
          }
        ],
        ref: "input",
        staticClass: "input",
        attrs: { placeholder: _vm.i18n("Filter") },
        domProps: { value: _vm.filter.value },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.filter, "value", $event.target.value)
          }
        }
      }),
      _vm.filter.value
        ? _c("search-mode", {
            staticClass: "is-right is-small individual-search-mode",
            attrs: {
              modes: _vm.state.template.searchModes,
              query: _vm.filter.value
            },
            on: {
              input: function($event) {
                return _vm.$refs.input.focus()
              }
            },
            model: {
              value: _vm.filter.mode,
              callback: function($$v) {
                _vm.$set(_vm.filter, "mode", $$v)
              },
              expression: "filter.mode"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }