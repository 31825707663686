var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    _vm._g(
      _vm._b(
        {
          staticClass: "button has-margin-left-small",
          class: _vm.button.class
        },
        "a",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("span", { staticClass: "is-hidden-mobile" }, [
        _vm._v(" " + _vm._s(_vm.i18n(_vm.button.label)) + " ")
      ]),
      _c(
        "span",
        { staticClass: "icon is-small" },
        [_c("fa", { attrs: { icon: _vm.button.icon } })],
        1
      ),
      _c("span", { staticClass: "is-hidden-mobile" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }