var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-length-menu", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var bindings = ref.bindings
          var events = ref.events
          return [
            _c(
              "vue-select",
              _vm._g(
                _vm._b(
                  { staticClass: "length-menu" },
                  "vue-select",
                  bindings,
                  false
                ),
                events
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }