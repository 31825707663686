var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field is-grouped" },
    [
      _c("dropdown", {
        ref: "dropdown",
        staticClass: "filters",
        class: { "has-select": _vm.hasSelect, "has-filter": _vm.filter },
        attrs: { manual: "" },
        on: { hide: _vm.close },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("fa", { attrs: { icon: "filter" } })],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "controls",
            fn: function() {
              return [
                _vm.filter
                  ? [
                      _c("div", { staticClass: "level is-marginless" }, [
                        _c("div", { staticClass: "level-item" }, [
                          _c("span", { staticClass: "tag is-bold is-info" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.i18n("Filter")) +
                                ": " +
                                _vm._s(_vm.i18n(_vm.filter.label)) +
                                " "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "level-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button is-small is-bold",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.filter = null
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.i18n("Clear")) + " ")]
                          ),
                          _vm.$refs.filter && _vm.$refs.filter.applicable
                            ? _c(
                                "a",
                                {
                                  staticClass: "button is-small is-bold",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.apply($event)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.i18n("Apply")) + " ")]
                              )
                            : _vm._e()
                        ])
                      ]),
                      _c("hr", {
                        staticClass: "is-dropdown-divider has-margin-medium"
                      })
                    ]
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "items",
            fn: function() {
              return [
                _vm.filter
                  ? _c(
                      "div",
                      { staticClass: "has-padding-medium" },
                      [
                        _c(_vm.filter.component, {
                          ref: "filter",
                          tag: "component",
                          attrs: { filter: _vm.filter }
                        })
                      ],
                      1
                    )
                  : _vm._l(_vm.filters, function(filter) {
                      return _c(
                        "dropdown-item",
                        {
                          key: filter.name,
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.select(filter)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.i18n(filter.label)) + " ")]
                      )
                    })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }