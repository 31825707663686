var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", { on: { close: _vm.closeConfirmation } }, [
    _c("div", { staticClass: "box" }, [
      _c("h5", { staticClass: "subtitle is-5" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.i18n(_vm.state.action.button.message || "Are you sure?")
            ) +
            " "
        )
      ]),
      _c("hr"),
      _c("div", { staticClass: "level" }, [
        _c("div", { staticClass: "level-left" }),
        _c("div", { staticClass: "level-right" }, [
          _c("div", { staticClass: "level-item" }, [
            _c(
              "button",
              {
                staticClass: "button is-outlined",
                on: { click: _vm.closeConfirmation }
              },
              [_vm._v(" " + _vm._s(_vm.i18n("Cancel")) + " ")]
            ),
            _c(
              "button",
              {
                directives: [{ name: "focus", rawName: "v-focus" }],
                staticClass: "button is-danger has-margin-left-small",
                on: { click: _vm.doButtonAction }
              },
              [_vm._v(" " + _vm._s(_vm.i18n("Yes")) + " ")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }