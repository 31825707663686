var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-search", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var clearEvents = ref.clearEvents
          var filterable = ref.filterable
          var modeBindings = ref.modeBindings
          var modeEvents = ref.modeEvents
          var i18n = ref.i18n
          var modeSelector = ref.modeSelector
          var search = ref.search
          var searchable = ref.searchable
          var searchBindings = ref.searchBindings
          var searchEvents = ref.searchEvents
          return [
            _c(
              "div",
              { staticClass: "field has-addons" },
              [
                filterable ? _c("filters") : _vm._e(),
                searchable
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "control is-expanded has-icons-left has-icons-right"
                      },
                      [
                        _c(
                          "input",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "input has-text-centered",
                                attrs: {
                                  type: "text",
                                  placeholder: i18n("Search")
                                }
                              },
                              "input",
                              searchBindings,
                              false
                            ),
                            searchEvents
                          )
                        ),
                        _c(
                          "span",
                          { staticClass: "icon is-small is-left" },
                          [_c("fa", { attrs: { icon: "search" } })],
                          1
                        ),
                        search
                          ? _c(
                              "span",
                              { staticClass: "is-right icon is-small" },
                              [
                                _c(
                                  "a",
                                  _vm._g(
                                    { staticClass: "delete is-small" },
                                    clearEvents
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        modeSelector
                          ? _c(
                              "search-mode",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "is-right is-small global-search-mode"
                                  },
                                  "search-mode",
                                  modeBindings,
                                  false
                                ),
                                modeEvents
                              )
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }