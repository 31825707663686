var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-filter" },
    [
      _c("div", { staticClass: "has-text-centered" }, [
        _c(
          "a",
          {
            staticClass: "button is-small is-bold",
            class: { "is-success": _vm.filter.mode !== "interval" },
            on: {
              click: function($event) {
                _vm.filter.mode = null
                _vm.filter.value = null
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.i18n("Value")) + " ")]
        ),
        _c(
          "a",
          {
            staticClass: "button is-small is-bold has-margin-left-medium",
            class: { "is-success": _vm.filter.mode === "interval" },
            on: {
              click: function($event) {
                _vm.filter.mode = "interval"
                _vm.filter.value = _vm.interval()
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.i18n("Interval")) + " ")]
        )
      ]),
      _vm.filter.mode === "interval"
        ? _c("div", { staticClass: "has-margin-top-medium" }, [
            _c("div", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left" }, [
                _c(
                  "div",
                  { staticClass: "level-item" },
                  [
                    _c("datepicker", {
                      directives: [{ name: "focus", rawName: "v-focus" }],
                      attrs: {
                        "alt-input": "",
                        placeholder: _vm.i18n("Min"),
                        "alt-format": _vm.state.template.dateFormat || ""
                      },
                      model: {
                        value: _vm.filter.value.min,
                        callback: function($$v) {
                          _vm.$set(_vm.filter.value, "min", $$v)
                        },
                        expression: "filter.value.min"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "level-right" }, [
                _c(
                  "div",
                  { staticClass: "level-item" },
                  [
                    _c("datepicker", {
                      attrs: {
                        "alt-input": "",
                        placeholder: _vm.i18n("Max"),
                        "alt-format": _vm.state.template.dateFormat
                      },
                      model: {
                        value: _vm.filter.value.max,
                        callback: function($$v) {
                          _vm.$set(_vm.filter.value, "max", $$v)
                        },
                        expression: "filter.value.max"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        : _c("datepicker", {
            directives: [{ name: "focus", rawName: "v-focus" }],
            staticClass: "has-margin-top-medium",
            attrs: {
              "alt-input": "",
              placeholder: _vm.i18n("Filter"),
              "alt-format": _vm.state.template.dateFormat
            },
            model: {
              value: _vm.filter.value,
              callback: function($$v) {
                _vm.$set(_vm.filter, "value", $$v)
              },
              expression: "filter.value"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }