var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "labels field is-grouped is-grouped-multiline has-background-light"
    },
    _vm._l(_vm.activeScenario().filters, function(filter, index) {
      return _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: function() {
                return _vm.hide(index)
              },
              expression: "() => hide(index)"
            }
          ],
          key: filter.type + "-" + index,
          staticClass: "control"
        },
        [
          _c("filter-label", {
            attrs: { filter: filter },
            on: {
              select: function($event) {
                return _vm.select(filter, index)
              },
              delete: function($event) {
                return _vm.clear(index)
              }
            }
          }),
          _vm.selectedIndex === index
            ? _c(
                "div",
                {
                  staticClass:
                    "filter-container dropdown-content has-padding-medium"
                },
                [
                  _c(_vm.selectedFilter.component, {
                    ref: "filter-" + index,
                    refInFor: true,
                    tag: "component",
                    attrs: { edit: "", filter: _vm.selectedFilter }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }