var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-table-header", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var actions = ref.actions
          var actionsLabel = ref.actionsLabel
          var clearSortEvents = ref.clearSortEvents
          var columns = ref.columns
          var crtNo = ref.crtNo
          var crtNoLabel = ref.crtNoLabel
          var hiddenColumns = ref.hiddenColumns
          var i18n = ref.i18n
          var preview = ref.preview
          var selectBindings = ref.selectBindings
          var selectEvents = ref.selectEvents
          var selectable = ref.selectable
          var sortEvents = ref.sortEvents
          var style = ref.style
          var visibleColumn = ref.visibleColumn
          return [
            _c("thead", [
              _c(
                "tr",
                { staticClass: "has-background-light", class: style },
                [
                  selectable
                    ? _c(
                        "th",
                        { staticClass: "table-header has-text-centered" },
                        [
                          _c("label", { staticClass: "checkbox" }, [
                            _c(
                              "input",
                              _vm._g(
                                _vm._b(
                                  { attrs: { type: "checkbox" } },
                                  "input",
                                  selectBindings,
                                  false
                                ),
                                selectEvents
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  hiddenColumns || preview ? _c("th") : _vm._e(),
                  crtNo
                    ? _c(
                        "th",
                        { staticClass: "table-header has-text-centered" },
                        [_vm._v(" " + _vm._s(i18n(crtNoLabel)) + " ")]
                      )
                    : _vm._e(),
                  _vm._l(columns, function(column) {
                    return [
                      visibleColumn(column)
                        ? _c(
                            "th",
                            {
                              key: column.label,
                              staticClass: "table-header has-text-centered"
                            },
                            [
                              column.meta.sortable
                                ? _c(
                                    "span",
                                    _vm._g(
                                      { staticClass: "is-clickable" },
                                      sortEvents(column)
                                    ),
                                    [
                                      _vm._v(
                                        " " + _vm._s(i18n(column.label)) + " "
                                      )
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      " " + _vm._s(i18n(column.label)) + " "
                                    )
                                  ]),
                              _c(
                                "span",
                                { staticClass: "table-header-controls" },
                                [
                                  column.tooltip
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: i18n(column.tooltip),
                                              expression: "i18n(column.tooltip)"
                                            }
                                          ],
                                          staticClass:
                                            "icon is-small has-text-info"
                                        },
                                        [
                                          _c("fa", {
                                            attrs: { icon: "info", size: "xs" }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  column.meta.sortable
                                    ? _c(
                                        "span",
                                        _vm._g(
                                          { staticClass: "sorter" },
                                          sortEvents(column)
                                        ),
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "icon is-small" },
                                            [
                                              !column.meta.sort
                                                ? _c("fa", {
                                                    attrs: {
                                                      icon: "sort",
                                                      size: "xs"
                                                    }
                                                  })
                                                : column.meta.sort === "ASC"
                                                ? _c("fa", {
                                                    attrs: {
                                                      icon: "sort-up",
                                                      size: "xs"
                                                    }
                                                  })
                                                : _c("fa", {
                                                    attrs: {
                                                      icon: "sort-down",
                                                      size: "xs"
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  column.meta.sort
                                    ? _c(
                                        "a",
                                        _vm._g(
                                          { staticClass: "delete is-small" },
                                          clearSortEvents(column)
                                        )
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  }),
                  actions
                    ? _c(
                        "th",
                        { staticClass: "table-header has-text-centered" },
                        [_vm._v(" " + _vm._s(i18n(actionsLabel)) + " ")]
                      )
                    : _vm._e()
                ],
                2
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }