var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "core-pagination",
    _vm._g(
      {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var fullRecordInfo = ref.fullRecordInfo
              var loading = ref.loading
              var atStart = ref.atStart
              var atEnd = ref.atEnd
              var i18n = ref.i18n
              var jumpTo = ref.jumpTo
              var middlePages = ref.middlePages
              var page = ref.page
              var pages = ref.pages
              return [
                _c(
                  "nav",
                  {
                    staticClass: "pagination is-small",
                    attrs: { role: "navigation", "aria-label": "pagination" }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "pagination-previous",
                        attrs: { disabled: page === 1 || loading },
                        on: {
                          click: function($event) {
                            return jumpTo(page - 1)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(i18n("Previous")) + " ")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "pagination-next",
                        attrs: { disabled: page === pages || loading },
                        on: {
                          click: function($event) {
                            return jumpTo(page + 1)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(i18n("Next")) + " ")]
                    ),
                    fullRecordInfo
                      ? _c(
                          "ul",
                          { staticClass: "pagination-list" },
                          [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "pagination-link",
                                  class: { "is-current": page === 1 },
                                  attrs: { disabled: loading },
                                  on: {
                                    click: function($event) {
                                      return jumpTo(1)
                                    }
                                  }
                                },
                                [_vm._v(" 1 ")]
                              )
                            ]),
                            pages > 5 && !atStart
                              ? _c("li", [
                                  _c(
                                    "span",
                                    { staticClass: "pagination-ellipsis" },
                                    [_vm._v(" … ")]
                                  )
                                ])
                              : _vm._e(),
                            _vm._l(middlePages, function(i) {
                              return _c("li", { key: i }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "pagination-link",
                                    class: { "is-current": page === i },
                                    attrs: { disabled: loading },
                                    on: {
                                      click: function($event) {
                                        return jumpTo(i)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(i) + " ")]
                                )
                              ])
                            }),
                            pages > 5 && !atEnd
                              ? _c("li", [
                                  _c(
                                    "span",
                                    { staticClass: "pagination-ellipsis" },
                                    [_vm._v(" … ")]
                                  )
                                ])
                              : _vm._e(),
                            pages > 1
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "pagination-link",
                                      class: { "is-current": page === pages },
                                      attrs: { disabled: loading },
                                      on: {
                                        click: function($event) {
                                          return jumpTo(pages)
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(pages) + " ")]
                                  )
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              ]
            }
          }
        ])
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }