var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tags has-addons" }, [
    _c(
      "a",
      {
        staticClass: "tag is-warning",
        on: {
          click: function($event) {
            return _vm.$emit("select")
          }
        }
      },
      [
        _vm._v(
          " " +
            _vm._s(_vm.label) +
            " " +
            _vm._s(_vm.preposition) +
            ": " +
            _vm._s(_vm.value) +
            " "
        )
      ]
    ),
    _c(
      "a",
      {
        staticClass: "tag has-background-warning",
        on: {
          click: function($event) {
            return _vm.$emit("delete")
          }
        }
      },
      [
        _c(
          "span",
          { staticClass: "icon is-small" },
          [_c("fa", { attrs: { icon: "times" } })],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }