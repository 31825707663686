var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-modal", {
    attrs: { show: _vm.show },
    on: { close: _vm.closeModal },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var close = ref.close
          return _c("fade", {}, [
            _vm.show
              ? _c("div", { class: ["modal", { "is-active": _vm.show }] }, [
                  _c("div", {
                    staticClass: "modal-background",
                    on: { click: close }
                  }),
                  _c("div", { staticClass: "modal-card modal-lg" }, [
                    _c("header", { staticClass: "modal-card-head" }, [
                      _c("div", { staticClass: "modal-title" }, [
                        _vm._v(" Preview Video ")
                      ])
                    ]),
                    _c("section", { staticClass: "form-box" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "columns form-section flex-column flex-centered"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "column is-12 section-title block_video is-paddingless"
                            },
                            [
                              _vm.src
                                ? _c("video", {
                                    ref: "videoRef",
                                    staticClass: "video",
                                    attrs: {
                                      src: _vm.src,
                                      id: "video-container",
                                      width: "100%",
                                      controls: ""
                                    }
                                  })
                                : _c(
                                    "div",
                                    { staticClass: "has-margin-left-large" },
                                    [_vm._v(" This section has no file ")]
                                  )
                            ]
                          )
                        ]
                      )
                    ]),
                    _c(
                      "footer",
                      {
                        staticClass: "modal-card-foot",
                        staticStyle: { "justify-content": "end" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "button is-outlined",
                            on: { click: _vm.closeModal }
                          },
                          [_vm._v(" " + _vm._s(_vm.i18n("Close")) + " ")]
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ])
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }