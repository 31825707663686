var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "core-table",
    _vm._g(
      _vm._b(
        {
          ref: "table",
          on: {
            ready: function($event) {
              _vm.ready = true
            }
          },
          scopedSlots: _vm._u(
            [
              _vm.ready
                ? {
                    key: "default",
                    fn: function() {
                      return [
                        _c(
                          "table-content",
                          _vm._g(
                            {
                              attrs: { "model-namespace": _vm.modelNamespace },
                              scopedSlots: _vm._u(
                                [
                                  _vm._l(_vm.slots, function(slot) {
                                    return {
                                      key: slot,
                                      fn: function(props) {
                                        return [_vm._t(slot, null, null, props)]
                                      }
                                    }
                                  })
                                ],
                                null,
                                true
                              )
                            },
                            _vm.$listeners
                          )
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        "core-table",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }