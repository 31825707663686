var render, staticRenderFns
import script from "./CoreTable.vue?vue&type=script&lang=js&"
export * from "./CoreTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/calgarystory/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f5fadec')) {
      api.createRecord('1f5fadec', component.options)
    } else {
      api.reload('1f5fadec', component.options)
    }
    
  }
}
component.options.__file = "src/js/components/enso/ui/bulma/components/draggable_table/renderless/CoreTable.vue"
export default component.exports