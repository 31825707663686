<template>
    <section class="data-table media-table">
        <enso-table class="box is-paddingless raises-on-hover"
                    id="medias"
                    :params="{ location: $route.params.location }"
                    :path="route('locations.medias.initTable', {}, false)"
                    model-namespace="\App\System\Locations\Models\Media">
            <template v-slot:logo="{ row }">
                <div v-if="row.logo" class="flex-centered">
                    <figure class="image is-32x32 flex-centered" style="margin: 0 auto;">
                        <img :src="'/storage/' + row.logo" alt="Placeholder image">
                    </figure>
                </div>
            </template>
            <template v-slot:image="{ row }">
                <div v-if="row.image" class="flex-centered">
                    <figure class="image is-32x32 flex-centered" style="margin: 0 auto;">
                        <img :src="'/storage/' + row.image" alt="Placeholder image">
                    </figure>
                </div>
            </template>
            <template v-slot:audio_src="{ row }">
                <a v-if="row.audio_src"
                   class="button is-small is-table-button has-margin-left-small is-row-button has-tooltip"
                   @click="listenAudioAsVideo(row)">
                    <span class="icon is-small">
                        <fa icon="volume-up"/>
                    </span>
                </a>
            </template>
            <template v-slot:video_src="{ row }">
                <a v-if="row.video_src"
                    class="button is-small is-table-button has-margin-left-small is-row-button has-tooltip"
                   @click="viewVideo(row)">
                    <span class="icon is-small">
                        <fa icon="video"/>
                    </span>
                </a>
            </template>
        </enso-table>
        <video-modal :show="video_modal" :src="video_src" @close="videoClose"/>
    </section>
</template>

<script>
import EnsoTable from '@components/enso/ui/bulma/components/draggable_table/bulma/EnsoTable.vue';
import VideoModal from '@components/medias/VideoModal.vue';

export default {
    name: 'Index',
    components: {
        EnsoTable,
        VideoModal,
    },
    inject: ['i18n', 'route'],
    data: () => ({
        video_modal: false,
        video_src: null,
        audio_modal: false,
        audio_src: null,
    }),
    methods: {
        viewVideo(row) {
            this.video_modal = true;
            if (row.video_src) {
                this.video_src = `${window.location.protocol}//${window.location.host}/storage/${row.video_src}`;
            }
        },
        videoClose() {
            this.video_modal = false;
            this.video_src = null;
        },
        listenAudioAsVideo(row) {
            this.video_modal = true;
            if (row.audio_src) {
                this.video_src = `${window.location.protocol}//${window.location.host}/storage/${row.audio_src}`;
            }
            // this.audio_modal = true;
            // if (row.audio_src) {
            //     this.audio_src = `${window.location.protocol}//${window.location.host}/storage/${row.audio_src}`;
            // }
        },
    },
};
</script>

<style lang="scss">
.media-table tbody {
    overflow-y: hidden;
    tr:hover {
        cursor: all-scroll;
    }
}
</style>
