var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: [_vm.boolean, _vm.clickable, _vm.icon],
      on: {
        click: function($event) {
          _vm.column.meta.clickable ? _vm.$emit("clicked") : null
        }
      }
    },
    [
      _vm.column.meta.slot
        ? _vm._t(_vm.column.name, [_vm._v(" " + _vm._s(_vm.value) + " ")])
        : _vm.column.meta.boolean
        ? _c("fa", {
            attrs: { icon: _vm.value ? "check" : "times", size: "sm" }
          })
        : _vm.column.meta.icon && _vm.value
        ? _c("fa", { attrs: { icon: _vm.value } })
        : _vm.column.enum
        ? [_vm._v(" " + _vm._s(_vm.column.enum._get(_vm.value)) + " ")]
        : _vm.column.meta.translatable
        ? [_vm._v(" " + _vm._s(_vm.i18n(_vm.value)) + " ")]
        : [_vm._v(_vm._s(_vm.value))]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }