var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tfoot", [
    _c(
      "tr",
      [
        _vm.state.template.selectable ? _c("td") : _vm._e(),
        _vm.hiddenColumns().length || _vm.state.template.preview
          ? _c("td", [
              _vm.hiddenTotals.length
                ? _c(
                    "span",
                    {
                      staticClass: "icon is-small hidden-control",
                      attrs: { "aria-visible": _vm.isExpanded },
                      on: {
                        click: function($event) {
                          _vm.isExpanded = !_vm.isExpanded
                        }
                      }
                    },
                    [_c("fa", { attrs: { icon: "chevron-right" } })],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.state.template.crtNo ? _c("td") : _vm._e(),
        _vm.visibleColumn(_vm.state.template.columns[0])
          ? _c("td", { staticClass: "has-text-centered is-bold" }, [
              _vm._v(" " + _vm._s(_vm.i18n("Total")) + " ")
            ])
          : _vm._e(),
        _vm._l(_vm.columns.length - 1, function(i) {
          return [
            _vm.visibleColumn(_vm.columns[i])
              ? _c(
                  "td",
                  {
                    key: i,
                    staticClass: "is-bold",
                    class: [
                      {
                        "is-number":
                          _vm.columns[i].money || _vm.columns[i].number
                      },
                      _vm.columnAlignment(_vm.columns[i])
                    ]
                  },
                  [
                    _vm.columns[i].meta.total ||
                    _vm.columns[i].meta.rawTotal ||
                    _vm.columns[i].meta.average
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.columns[i].money || _vm.columns[i].number
                                ? _vm.state.body.total[_vm.columns[i].name]
                                : _vm.totalFormat(
                                    _vm.state.body.total[_vm.columns[i].name]
                                  )
                            )
                          )
                        ])
                      : _vm.columns[i].meta.customTotal
                      ? _vm._t(_vm.columns[i].name + "_custom_total", null, {
                          total: _vm.state.body.total
                        })
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e()
          ]
        }),
        _vm.state.template.actions ? _c("td") : _vm._e()
      ],
      2
    ),
    _vm.isExpanded
      ? _c("tr", [
          _c(
            "td",
            {
              staticClass: "has-text-right",
              attrs: { colspan: _vm.hiddenColspan() }
            },
            [
              _c(
                "ul",
                _vm._l(_vm.hiddenTotals, function(cell) {
                  return _c(
                    "li",
                    {
                      key: cell.name,
                      staticClass: "is-bold",
                      class: [
                        { "is-money": cell.money || cell.number },
                        _vm.columnAlignment(cell)
                      ]
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.i18n(cell.label)) + ":")]),
                      cell.meta.total || cell.meta.rawTotal
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                cell.money || cell.number
                                  ? _vm.state.body.total[cell.name]
                                  : _vm.totalFormat(
                                      _vm.state.body.total[cell.name]
                                    )
                              )
                            )
                          ])
                        : cell.meta.customTotal
                        ? _vm._t(cell.name + "_custom_total", null, {
                            total: _vm.state.body.total
                          })
                        : _vm._e()
                    ],
                    2
                  )
                }),
                0
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }