var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-records-info", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var chunkInfo = ref.chunkInfo
          var fromInfo = ref.fromInfo
          var hasFilters = ref.hasFilters
          var hasSelected = ref.hasSelected
          var selectedInfo = ref.selectedInfo
          return [
            _c("div", { staticClass: "records-info has-text-centered-touch" }, [
              _vm._v(" " + _vm._s(chunkInfo) + " "),
              hasFilters
                ? _c("span", [_vm._v(" " + _vm._s(fromInfo) + " ")])
                : _vm._e(),
              hasSelected
                ? _c("span", [_vm._v(" " + _vm._s(selectedInfo) + " ")])
                : _vm._e()
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }