var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    { staticClass: "control" },
    [
      _c("vue-select", {
        attrs: {
          objects: "",
          i18n: _vm.i18n,
          label: _vm.filter.selectLabel,
          source: _vm.filter.path,
          multiple: _vm.filter.multiple,
          translated: _vm.filter.translated,
          params: _vm.filter.params,
          "pivot-params": _vm.filter.pivotParams
        },
        model: {
          value: _vm.filter.value,
          callback: function($$v) {
            _vm.$set(_vm.filter, "value", $$v)
          },
          expression: "filter.value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }