var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "core-table-body",
    _vm._g(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var actionBindings = ref.actionBindings
                var actionEvents = ref.actionEvents
                var cellBindings = ref.cellBindings
                var cellEvents = ref.cellEvents
                var hiddenColspan = ref.hiddenColspan
                var hiddenCount = ref.hiddenCount
                var isExpanded = ref.isExpanded
                var shouldRender = ref.shouldRender
                var rowCrtNo = ref.rowCrtNo
                var selectEvents = ref.selectEvents
                var hiddenEvents = ref.hiddenEvents
                var isHighlighted = ref.isHighlighted
                var selectBindings = ref.selectBindings
                var columnAlignment = ref.columnAlignment
                var isChild = ref.isChild
                var i18n = ref.i18n
                var visibleColumn = ref.visibleColumn
                var state = ref.state
                return [
                  _c(
                    "draggable",
                    {
                      attrs: { tag: "tbody" },
                      on: { end: _vm.reorder },
                      model: {
                        value: state.body.data,
                        callback: function($$v) {
                          _vm.$set(state.body, "data", $$v)
                        },
                        expression: "state.body.data"
                      }
                    },
                    _vm._l(state.body.data, function(row, index) {
                      var _obj
                      return _c(
                        "tr",
                        {
                          key: row[state.template.dtRowId],
                          class:
                            ((_obj = {}),
                            (_obj[state.template.highlight] = isHighlighted(
                              row[state.template.dtRowId]
                            )),
                            _obj)
                        },
                        [
                          isChild(row)
                            ? _c(
                                "td",
                                {
                                  class: state.template.align,
                                  attrs: { colspan: hiddenColspan() }
                                },
                                [
                                  _c(
                                    "ul",
                                    [
                                      _vm._l(row, function(cell) {
                                        return [
                                          !cell.column.meta.rogue
                                            ? _c(
                                                "li",
                                                {
                                                  key: cell.column.label,
                                                  staticClass: "child-row",
                                                  class: cell.column.class
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        i18n(cell.column.label)
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(": "),
                                                  _c(
                                                    "table-cell",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          i18n: i18n,
                                                          column: cell.column,
                                                          value: cell.value
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            cell.column.meta
                                                              .slot
                                                              ? {
                                                                  key:
                                                                    cell.column
                                                                      .name,
                                                                  fn: function() {
                                                                    return [
                                                                      _vm._t(
                                                                        cell
                                                                          .column
                                                                          .name,
                                                                        null,
                                                                        {
                                                                          row:
                                                                            state
                                                                              .body
                                                                              .data[
                                                                              cell
                                                                                .rowCrtNo
                                                                            ],
                                                                          column:
                                                                            cell.column,
                                                                          loading:
                                                                            state
                                                                              .meta
                                                                              .loading
                                                                        }
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              : null
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      cellEvents(
                                                        state.body.data[
                                                          index - 1
                                                        ],
                                                        cell.column
                                                      )
                                                    )
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      }),
                                      state.template.preview
                                        ? _c(
                                            "li",
                                            [
                                              _vm._t("preview", null, {
                                                row: state.body.data[index - 1]
                                              })
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ]
                              )
                            : [
                                state.template.selectable
                                  ? _c("td", { class: state.template.align }, [
                                      _c("div", { staticClass: "selectable" }, [
                                        _c(
                                          "label",
                                          { staticClass: "checkbox" },
                                          [
                                            _c(
                                              "input",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: { type: "checkbox" }
                                                  },
                                                  "input",
                                                  selectBindings(row),
                                                  false
                                                ),
                                                selectEvents(row)
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                hiddenCount || state.template.preview
                                  ? _c("td", [
                                      _c(
                                        "span",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "icon is-small hidden-control",
                                            attrs: {
                                              "aria-visible": isExpanded(row)
                                            }
                                          },
                                          hiddenEvents(row, index)
                                        ),
                                        [
                                          _c("fa", {
                                            attrs: { icon: "chevron-right" }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e(),
                                state.template.crtNo
                                  ? _c("td", { class: state.template.align }, [
                                      _c("span", { staticClass: "crt-no" }, [
                                        _vm._v(
                                          " " + _vm._s(rowCrtNo(row)) + " "
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._l(state.template.columns, function(
                                  column,
                                  i
                                ) {
                                  return [
                                    visibleColumn(column)
                                      ? _c(
                                          "td",
                                          {
                                            key: i,
                                            class: [
                                              {
                                                "is-number":
                                                  column.money || column.number
                                              },
                                              columnAlignment(column),
                                              column.class
                                            ]
                                          },
                                          [
                                            _c(
                                              "table-cell",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        column.meta.slot
                                                          ? {
                                                              key: column.name,
                                                              fn: function() {
                                                                return [
                                                                  _vm._t(
                                                                    column.name,
                                                                    null,
                                                                    {
                                                                      row: row,
                                                                      column: column,
                                                                      loading:
                                                                        state
                                                                          .meta
                                                                          .loading
                                                                    }
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          : null
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  "table-cell",
                                                  cellBindings(row, column),
                                                  false
                                                ),
                                                cellEvents(row, column)
                                              )
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                }),
                                state.template.actions && !isChild(row)
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "table-actions",
                                        class: state.template.align
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "action-buttons" },
                                          [
                                            _vm._l(
                                              state.template.buttons.row,
                                              function(button, idx) {
                                                return [
                                                  shouldRender(row, button)
                                                    ? [
                                                        button.slot
                                                          ? _vm._t(
                                                              button.slot,
                                                              null,
                                                              {
                                                                icon:
                                                                  button.icon,
                                                                label:
                                                                  button.label,
                                                                route:
                                                                  button.route,
                                                                event:
                                                                  button.event,
                                                                cssClass:
                                                                  button.class,
                                                                row: row
                                                              }
                                                            )
                                                          : _c(
                                                              "a",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "tooltip",
                                                                        rawName:
                                                                          "v-tooltip.left",
                                                                        value: button.tooltip
                                                                          ? i18n(
                                                                              button.tooltip
                                                                            )
                                                                          : null,
                                                                        expression:
                                                                          "button.tooltip ? i18n(button.tooltip) : null",
                                                                        modifiers: {
                                                                          left: true
                                                                        }
                                                                      }
                                                                    ],
                                                                    key: idx,
                                                                    staticClass:
                                                                      "button is-small is-table-button has-margin-left-small",
                                                                    class:
                                                                      button.class
                                                                  },
                                                                  "a",
                                                                  actionBindings(
                                                                    button,
                                                                    row
                                                                  ),
                                                                  false
                                                                ),
                                                                actionEvents(
                                                                  button,
                                                                  row
                                                                )
                                                              ),
                                                              [
                                                                button.label
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            i18n(
                                                                              button.label
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ])
                                                                  : _vm._e(),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "icon is-small"
                                                                  },
                                                                  [
                                                                    _c("fa", {
                                                                      attrs: {
                                                                        icon:
                                                                          button.icon
                                                                      }
                                                                    })
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                      ]
                                                    : _vm._e()
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                        ],
                        2
                      )
                    }),
                    0
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }