var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "level" }, [
    _c("div", { staticClass: "level-left" }, [
      _c("div", { staticClass: "level-item" }, [
        _vm._v(" " + _vm._s(_vm.i18n("Value")) + " ")
      ])
    ]),
    _c("div", { staticClass: "level-right" }, [
      _c(
        "div",
        { staticClass: "level-item" },
        [
          _c("vue-switch", {
            staticClass: "is-medium",
            model: {
              value: _vm.filter.value,
              callback: function($$v) {
                _vm.$set(_vm.filter, "value", $$v)
              },
              expression: "filter.value"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }