var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns is-gapless has-background-light bottom-controls" },
    [
      _c("div", { staticClass: "column" }, [_c("records-info")], 1),
      _c(
        "div",
        { staticClass: "column is-narrow has-text-right" },
        [
          _vm.hasEntries()
            ? _c("pagination", _vm._g({}, _vm.$listeners))
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }