var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-style-selector", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var bindings = ref.bindings
          var events = ref.events
          return [
            _c(
              "vue-select",
              _vm._g(
                _vm._b(
                  {
                    staticClass: "style-selector",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "has-vertically-centered-content"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon" },
                                    [_c("fa", { attrs: { icon: "table" } })],
                                    1
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "vue-select",
                  bindings,
                  false
                ),
                events
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }