<template>
    <core-modal :show="show" @close="closeModal">
        <fade slot-scope="{ close }">
            <div v-bind:class="['modal', { 'is-active': show }]"
                 v-if="show">
                <div class="modal-background" @click="close"/>
                <div class="modal-card modal-lg">
                    <header class="modal-card-head">
                        <div class="modal-title">
                            Preview Video
                        </div>
                    </header>
                    <section class="form-box">
                        <div class="columns form-section flex-column flex-centered">
                            <div class="column is-12 section-title block_video is-paddingless">
                                <video v-if="src"
                                       class="video" ref="videoRef"
                                       :src="src" id="video-container" width="100%" controls/>
                                <div v-else class="has-margin-left-large">
                                    This section has no file
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot" style="justify-content: end;">
                        <button class="button is-outlined"
                                @click="closeModal">
                            {{ i18n('Close') }}
                        </button>
                    </footer>
                </div>
            </div>
        </fade>
    </core-modal>
</template>

<script>
import CoreModal from '@enso-ui/modal/src/renderless/CoreModal.vue';
import { Fade } from '@enso-ui/transitions';

export default {
    name: 'VideoModal',
    inject: ['i18n', 'route'],
    components: {
        CoreModal,
        Fade,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        src: {
            type: String,
            default: null,
        },
    },
    methods: {
        closeModal() {
            this.$emit('close', false);
        },
    },
};
</script>

<style>
.video {
    max-height: calc(100vh - 200px);
    background: whitesmoke;
}
.block_video {
    background: whitesmoke;
}
</style>
