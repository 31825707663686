var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-table",
    _vm._g(
      _vm._b(
        {
          ref: "table",
          attrs: {
            path: _vm.path,
            "error-handler": _vm.errorHandler,
            "model-namespace": _vm.modelNamespace,
            i18n: _vm.i18n
          },
          on: {
            ready: function($event) {
              _vm.ready = true
            }
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.slots, function(slot) {
                return {
                  key: slot,
                  fn: function(props) {
                    return [_vm._t(slot, null, null, props)]
                  }
                }
              })
            ],
            null,
            true
          )
        },
        "vue-table",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }