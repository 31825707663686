var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "data-table media-table" },
    [
      _c("enso-table", {
        staticClass: "box is-paddingless raises-on-hover",
        attrs: {
          id: "medias",
          params: { location: _vm.$route.params.location },
          path: _vm.route("locations.medias.initTable", {}, false),
          "model-namespace": "\\App\\System\\Locations\\Models\\Media"
        },
        scopedSlots: _vm._u([
          {
            key: "logo",
            fn: function(ref) {
              var row = ref.row
              return [
                row.logo
                  ? _c("div", { staticClass: "flex-centered" }, [
                      _c(
                        "figure",
                        {
                          staticClass: "image is-32x32 flex-centered",
                          staticStyle: { margin: "0 auto" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/storage/" + row.logo,
                              alt: "Placeholder image"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "image",
            fn: function(ref) {
              var row = ref.row
              return [
                row.image
                  ? _c("div", { staticClass: "flex-centered" }, [
                      _c(
                        "figure",
                        {
                          staticClass: "image is-32x32 flex-centered",
                          staticStyle: { margin: "0 auto" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/storage/" + row.image,
                              alt: "Placeholder image"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "audio_src",
            fn: function(ref) {
              var row = ref.row
              return [
                row.audio_src
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "button is-small is-table-button has-margin-left-small is-row-button has-tooltip",
                        on: {
                          click: function($event) {
                            return _vm.listenAudioAsVideo(row)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon is-small" },
                          [_c("fa", { attrs: { icon: "volume-up" } })],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "video_src",
            fn: function(ref) {
              var row = ref.row
              return [
                row.video_src
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "button is-small is-table-button has-margin-left-small is-row-button has-tooltip",
                        on: {
                          click: function($event) {
                            return _vm.viewVideo(row)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon is-small" },
                          [_c("fa", { attrs: { icon: "video" } })],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("video-modal", {
        attrs: { show: _vm.video_modal, src: _vm.video_src },
        on: { close: _vm.videoClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }