var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-table" },
    [
      _c(
        "top-controls",
        _vm._g(
          {
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.controlSlots(), function(slot) {
                  return {
                    key: slot,
                    fn: function(props) {
                      return [_vm._t(slot, null, null, props)]
                    }
                  }
                })
              ],
              null,
              true
            )
          },
          _vm.$listeners
        )
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "responsive",
              rawName: "v-responsive",
              value: _vm.state.template.responsive,
              expression: "state.template.responsive"
            }
          ],
          staticClass: "table-responsive"
        },
        [
          _vm.hasContent()
            ? _c(
                "table",
                {
                  staticClass: "table is-fullwidth is-marginless",
                  class: _vm.state.template.style,
                  attrs: { id: _vm.id }
                },
                [
                  _c("table-header", { ref: "header" }),
                  _c(
                    "table-body",
                    _vm._g(
                      {
                        attrs: { "model-namespace": _vm.modelNamespace },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.bodySlots(), function(slot) {
                              return {
                                key: slot,
                                fn: function(props) {
                                  return [_vm._t(slot, null, null, props)]
                                }
                              }
                            })
                          ],
                          null,
                          true
                        )
                      },
                      _vm.$listeners
                    )
                  ),
                  _vm.hasFooter()
                    ? _c("table-footer", {
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.customTotals(), function(customTotal) {
                              return {
                                key: customTotal,
                                fn: function(props) {
                                  return [
                                    _vm._t(customTotal, null, null, props)
                                  ]
                                }
                              }
                            })
                          ],
                          null,
                          true
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.state.meta.loading === true ? _c("loader") : _vm._e()
        ],
        1
      ),
      _vm.hasContent()
        ? _c("bottom-controls", _vm._g({}, _vm.$listeners))
        : _vm._e(),
      _vm.isEmpty()
        ? _c("div", { staticClass: "has-text-centered no-records-found" }, [
            _vm._v(" " + _vm._s(_vm.i18n("No records were found")) + " ")
          ])
        : _vm._e(),
      _vm.state.confirmation ? _c("confirmation") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }