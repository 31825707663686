var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "scenarios field is-grouped is-grouped-multiline has-background-light"
    },
    [
      _c("div", { staticClass: "control" }, [
        _c("div", { staticClass: "tags has-addons" }, [
          _c(
            "a",
            {
              staticClass: "tag is-bold",
              class: _vm.none ? "is-info" : "is-warning",
              on: { click: _vm.all }
            },
            [_vm._v(" " + _vm._s(_vm.i18n("all")) + " ")]
          )
        ])
      ]),
      _vm._l(_vm.state.filterScenarios, function(scenario, index) {
        return _c("div", { key: index, staticClass: "control" }, [
          _c(
            "div",
            { staticClass: "tags has-addons" },
            [
              scenario.edit && scenario.active
                ? _c(
                    "a",
                    {
                      staticClass: "tag is-bold",
                      class: scenario.active ? "is-info" : "is-warning"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: scenario.name,
                            expression: "scenario.name"
                          },
                          { name: "resize", rawName: "v-resize" },
                          { name: "focus", rawName: "v-focus" },
                          {
                            name: "select-on-focus",
                            rawName: "v-select-on-focus"
                          }
                        ],
                        staticClass:
                          "input is-small has-background-info has-text-white is-bold",
                        class: { "has-background-danger": _vm.duplicate },
                        domProps: { value: scenario.name },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.save($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(scenario, "name", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "tag is-bold",
                      class: scenario.active ? "is-info" : "is-warning",
                      on: {
                        click: function($event) {
                          return _vm.select(scenario)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(scenario.name) + " ")]
                  ),
              scenario.active
                ? [
                    scenario.edit
                      ? [
                          _c(
                            "a",
                            {
                              staticClass: "tag is-info",
                              on: { click: _vm.cancel }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-small" },
                                [_c("fa", { attrs: { icon: "ban" } })],
                                1
                              )
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "tag is-info has-text-white",
                              on: { click: _vm.save }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-small" },
                                [_c("fa", { attrs: { icon: "check" } })],
                                1
                              )
                            ]
                          )
                        ]
                      : [
                          _c(
                            "a",
                            {
                              staticClass: "tag is-info",
                              on: { click: _vm.edit }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-small" },
                                [_c("fa", { attrs: { icon: "pencil-alt" } })],
                                1
                              )
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "tag is-info has-text-white",
                              on: { click: _vm.remove }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-small" },
                                [_c("fa", { attrs: { icon: "times" } })],
                                1
                              )
                            ]
                          )
                        ]
                  ]
                : _vm._e()
            ],
            2
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }